<template>
	<a-modal
		v-model="showModal"
		:title="title"
		centered
		cancel-text="Cancelar"
		ok-text="Salvar"
		style="width: 100%;"
		@ok="handleSubmit"
		@cancel="cancelModal"
	>
		<a-row>
			<form hideRequiredMark>
				<a-form-item
					label="Mesa: "
					:label-col="{ span: 7 }"
					:wrapper-col="{ span: 15 }"
				>
					<a-input
						v-model="tableName"
						:auto-focus="true"
						:max-length="15"
						@keyup.enter="handleSubmit"
					/>
					<span class="size-span"> {{ tableName.length }}/15 </span>
				</a-form-item>
			</form>
		</a-row>
	</a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'TableModal',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showModal: false,
			tableName: '',
			extIndex: undefined,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
			allTables: 'tables/allTables',
		}),
	},
	methods: {
		...mapActions('tables', ['addTables', 'editTable']),
		setShowModal(index) {
			this.tableName = this.allTables[index].name
			this.extIndex = index
			this.showModal = true
		},
		cancelModal() {
			this.tableName = ''
			this.extIndex = -1
		},
		handleSubmit() {
			if (this.tableName) {
				const paylaod = {
					merchantId: this.selectedMerchantId,
					tableId: this.allTables[this.extIndex].id,
					name: this.tableName,
					index: this.extIndex,
				}

				this.editTable(paylaod)

				this.showModal = false
				this.cancelModal()
			}
		},
	},
}
</script>

<style lang="less" scoped>
.size-span {
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	line-height: 10px;
	float: right;
	padding-top: 3px;
}
</style>
