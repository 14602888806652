<template>
	<div>
		<a-popconfirm
			:visible="visible"
			ok-text="Confirmar"
			cancel-text="Cancelar"
			:ok-button-props="{ props: { disabled: !validated } }"
			placement="bottom"
			@confirm="handleOk"
			@cancel="cancel"
		>
			<a-icon slot="icon" type="caret-right" style="color: #fff;" />
			<template v-slot:title>
				<a-row
					style="
						padding: 5px 0;
						border-bottom: 1px solid hsv(0, 0, 85%);
					"
				>
					<a-checkbox
						:indeterminate="indeterminate"
						:checked="allChecked"
						@change="onCheckAll"
					>
						{{ allChecked ? 'Desmarcar' : 'Marcar' }} todos
					</a-checkbox>
				</a-row>
				<a-row style="max-width: 160px; margin: 10px 15px;">
					<a-checkbox-group
						:options="options"
						:value="checkeds"
						@change="onChange"
					/>
				</a-row>
				<a-divider orientation="left" class="divider">
					Destino
				</a-divider>
				<a-row>
					<a-select
						placeholder="Selecionar..."
						style="width: 145px; margin: 0 0 10px 15px;"
						@change="(value) => (destiny = value)"
					>
						<a-select-option
							v-for="table in tableList"
							:key="table.id"
							:value="table.id"
						>
							Mesa {{ table.name }}
						</a-select-option>
					</a-select>
				</a-row>
			</template>
			<slot></slot>
		</a-popconfirm>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'SwitchTabelModal',
	data() {
		return {
			visible: false,
			orders: [],
			checkeds: [],
			options: [],
			selectedTableId: null,
			destiny: null,
		}
	},
	computed: {
		...mapGetters({
			allTables: 'tables/allTables',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		allChecked: function () {
			return this.count === this.orders.length
		},
		indeterminate: function () {
			return this.count > 0 && this.count !== this.orders.length
		},
		count: function () {
			return this.checkeds.length
		},
		validated: function () {
			return (this.allChecked || this.indeterminate) && this.destiny
		},
		tableList: function () {
			return this.allTables.filter(
				(table) => table.id !== this.selectedTableId
			)
		},
	},
	beforeMount() {
		if (this.allTables.length === 0) this.fetchTables()
	},
	methods: {
		...mapActions('tables', ['fetchTables']),
		setShowModal(table) {
			if (!this.visible) {
				this.selectedTableId = table.id
				this.orders = table.orders
				this.mount()
				this.visible = true
			}
		},
		handleOk() {
			this.visible = false
			this.$emit('save', {
				tableId: this.destiny,
				ordersIds: this.checkeds,
				merchantId: this.selectedMerchantId,
			})
			this.clearModal()
		},
		cancel() {
			this.visible = false
			this.clearModal()
		},
		clearModal() {
			this.orders = []
			this.checkeds = []
			this.options = []
			this.destiny = null
		},
		mount() {
			this.options = this.orders.map((order) => {
				this.checkeds.push(order.id)
				return {
					value: order.id,
					label: 'Pedido ' + order.number,
				}
			})
		},
		onCheckAll(e) {
			this.checkeds = e.target.checked
				? this.orders.map((order) => order.id)
				: []
		},
		onChange(checkedValues) {
			this.checkeds = checkedValues
		},
	},
}
</script>

<style lang="less" scoped>
.element-list {
	list-style: none;
	padding: 5px 0;
	margin: 0;
}

.divider {
	margin: 0 0 5px -10px;
	padding-bottom: 0;
	max-width: 160px;
}
</style>
