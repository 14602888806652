<template>
	<div>
		<PageHeader :title="'Mesa ' + selectedTable.name">
			<SwitchTableModal
				v-if="hasOrders"
				ref="switchTableModal"
				@save="handleSwitch"
			>
				<a-button
					@click="
						() => $refs.switchTableModal.setShowModal(selectedTable)
					"
				>
					<a-icon type="swap" />
					Mudar de mesa
				</a-button>
			</SwitchTableModal>
		</PageHeader>
		<a-card
			:body-style="{ padding: '2px 8px', height: '100%' }"
			class="order-card"
			:tab-list="tabList"
			:active-tab-key="key"
			size="small"
			@tabChange="
				(selected) => {
					key = selected
				}
			"
		>
			<div class="orders-info-body">
				<OrdersList
					:orders="orders"
					:table="{ orders }"
					:loading="isLoading"
					@reload="onReload"
				/>
			</div>
			<a-tooltip slot="tabBarExtraContent">
				<template slot="title">
					Atualizar
				</template>
				<a-button
					icon="reload"
					@click="() => getData($route.params.id)"
				/>
			</a-tooltip>
		</a-card>
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { SwitchTableModal } from './components'
import { OrdersList } from '../ordersPage/components/'
import { mapGetters, mapActions } from 'vuex'
import config from '@/config/defaultSettings'

export default {
	name: 'TableView',
	components: {
		PageHeader,
		OrdersList,
		SwitchTableModal,
	},

	data() {
		return {
			key: 'PENDING',
			tabList: [
				{
					key: 'PENDING',
					tab: 'Pendentes',
				},
				{
					key: 'ANALYZED',
					tab: 'Analisados',
				},
			],
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'tables/isLoading',
			selectedTable: 'tables/tableSelected',
		}),
		orders: function () {
			const pedingStatus = ['WAITING', 'PAYING']
			const analyzedStatus = ['RECEIVING', 'OPEN', 'RESTRICTION', 'PAID']

			return this.selectedTable.orders.filter((order) => {
				return this.key === 'PENDING'
					? pedingStatus.includes(order.status)
					: analyzedStatus.includes(order.status)
			})
		},
		hasOrders: function () {
			return this.selectedTable && this.selectedTable.orders.length > 0
		},
	},
	watch: {
		selectedTable: {
			immediate: true,
			handler: async function () {
				await this.getData(this.$route.params.id)

				const title = this.$route.meta.title
				document.title = `${
					title ? `${title} ${this.selectedTable.name} - ` : ''
				}${config.appName}`
			},
		},
	},
	methods: {
		...mapActions('tables', ['selectTable', 'switchTable']),
		async getData(tableId) {
			return await this.selectTable(tableId)
		},
		async handleSwitch(payload) {
			const { tableId } = payload

			await this.switchTable(payload)
			await this.getData(tableId)

			this.$router.push({
				path: `/tables/table/${tableId}`,
			})
		},
		onReload() {
			this.getData(this.$route.params.id)
		},
	},
	async beforeRouteUpdate(to, from, next) {
		await this.getData(to.params.id)
		next()
	},
}
</script>

<style lang="less" scoped>
.order-card {
	height: 100%;
	border-radius: 5px;

	.orders-info-main {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;

		.orders-info-header {
			width: 100%;
		}
		.orders-info-body {
			flex: 1 1;
			padding: 0px 5px;
			.orders-info-title {
				color: rgba(0, 0, 0, 0.85);
				font-size: 20px;
				font-weight: 500;
				line-height: 28px;
				margin-bottom: 12px;
			}
			.orders-info-view {
				padding-top: 12px;
			}
		}
	}
}
</style>
