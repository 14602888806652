<template>
	<div
		:class="['table-container', isOccupied ? 'table-occupied' : '']"
		:style="
			isOccupied
				? `width: ${size + 10}px; height: ${size}px;`
				: `width: ${size + 10}px; height: ${size}px;`
		"
		@click="handleClick"
	>
		<a-row type="flex" justify="end" class="table-top" :gutter="2">
			<a-col>
				<a-tooltip placement="top" title="Pedidos Pendentes">
					<a-badge :count="ordersPendingCount" />
				</a-tooltip>
			</a-col>
		</a-row>
		<a-row class="table-center">
			<div
				class="table-name"
				:style="isOccupied ? 'background: #f57d31; color:#fff;' : ''"
			>
				{{ table.name }}
			</div>
		</a-row>
		<a-row
			class="table-footer"
			type="flex"
			justify="start"
			align="middle"
			:gutter="8"
		>
			<a-col>
				<a-tooltip placement="bottom" title="Total de pedidos">
					<a-badge
						:count="ordersCount"
						:number-style="{ backgroundColor: '#52c41a' }"
					/>
				</a-tooltip>
			</a-col>
			<a-col>
				<a-tooltip placement="bottom" title="Itens entregues">
					<span
						v-if="requestCount.total > 0"
						class="request-count"
						:style="allDelivered() ? 'color: #87d068;' : ''"
					>
						{{ requestCount.delivered }}/{{ requestCount.total }}
					</span>
				</a-tooltip>
			</a-col>
			<a-col :span="18" style="font-size: 22px;">
				<a-dropdown
					v-if="!isOccupied"
					:trigger="['click']"
					placement="bottomCenter"
				>
					<a-icon type="ellipsis" class="actions-button" />
					<a-menu slot="overlay">
						<a-menu-item key="1">
							<span @click="onEdit">
								<a-icon
									type="edit"
									style="padding: 0 10px 0 0;"
								/>
								Editar
							</span>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-col>
		</a-row>
	</div>
</template>

<script>
export default {
	props: {
		table: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		size: {
			type: Number,
			required: true,
		},
	},
	computed: {
		isOccupied: function () {
			return this.table.orders.length > 0
		},
		requestCount: function () {
			return {
				delivered: this.table.orders.reduce((cc, curr) => {
					const delivereds = curr.elements.filter(
						(request) => request.status === 'DELIVERED'
					)
					return cc + delivereds.length
				}, 0),

				total: this.table.orders.reduce(function (cc, curr) {
					return cc + curr.elements.length
				}, 0),
			}
		},
		ordersPendingCount: function () {
			const pendingStatus = ['WAITING', 'PAYING']

			return this.table.orders.reduce((cc, curr) => {
				return (pendingStatus.includes(curr.status) && cc + 1) || cc
			}, 0)
		},
	},
	methods: {
		onEdit() {
			this.$emit('edit', this.index)
		},
		allDelivered() {
			return this.requestCount.delivered == this.requestCount.total
		},
		handleClick() {
			if (this.isOccupied)
				this.$router.push({
					path: `/tables/${this.table.id}`,
				})
		},
		ordersCount() {
			return this.table.orders.length
		},
	},
}
</script>

<style lang="less" scoped>
.table-container {
	margin: 10px;
	border: 1px solid #d8d8d8;
	border-radius: 10px;
	align-content: center;
	overflow: hidden;

	.table-top {
		height: 33%;
		align-items: center;
		padding: 0 12px;
	}

	.table-center {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 33%;

		.table-name {
			display: inline;
			background: #d7d7d7;
			padding: 5px;
			margin: 0;
			min-width: 35px;
			text-align: center;
			border-radius: 5px;
			color: #979797;
			font-weight: bold;
		}

		.orders-count {
			margin-left: px;
			position: relative;
			left: 70%;
			top: 10%;
			z-index: 9;
		}
	}

	.table-footer {
		padding: 0 10px;
		height: 38%;
		align-items: center;

		.actions-button {
			float: right;
			padding-top: 5px;
			cursor: pointer;
			color: #a7a7a7;

			:hover {
				color: #707070;
			}
		}

		.request-count {
			font-size: 12px;
			font-weight: 550;
			color: rgba(0, 0, 0, 0.4);
		}
	}
}

.table-occupied {
	cursor: pointer;
	border-color: #f57d31;

	&:hover {
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	}
}
</style>
