<template>
	<div>
		<div v-if="isLoading" class="loading-spin">
			<a-spin>
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 50px;"
					spin
				/>
			</a-spin>
		</div>
		<div v-else>
			<a-row v-if="totalTables === 0">
				<p class="empty-list">
					Nenhuma mesa cadastrada até o momento.
				</p>
			</a-row>
			<a-row v-else class="tables-container">
				<Table
					v-for="(table, index) in allTables"
					:key="index"
					:table="table"
					:index="index"
					:size="sliderSize"
					@edit="handleEdit"
				/>
			</a-row>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Table from './table'

export default {
	name: 'TablesList',
	components: {
		Table,
	},
	computed: {
		...mapGetters('tables', [
			'isLoading',
			'allTables',
			'totalTables',
			'sliderSize',
		]),
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
		}),
	},
	watch: {
		merchantId: function () {
			if (this.isMerchantActive) this.fetchTables()
		},
	},
	beforeMount() {
		if (this.isMerchantActive) this.fetchTables()
	},
	methods: {
		...mapActions('tables', ['fetchTables']),
		handleEdit(index) {
			this.$emit('edit', index)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');
.tables-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}
</style>
